
import { defineComponent, reactive, ref } from "vue";
import { getOperationRecords } from "@/API/vl/vlSICCOChecking";
import setColumnWidth from "@/utils/rv/setColumnWidth";

const columns = [
  { title: "操作角色", dataIndex: "opRole", width: 100 },
  { title: "操作动作", dataIndex: "opAction", width: 200 },
  { title: "内容", dataIndex: "logDetail", width: 200 },
  { title: "操作时间", dataIndex: "opTime", width: 150 }
];
const tableHeight = window.innerHeight - 220;
export default defineComponent({
  setup() {
    const vinNo = sessionStorage.getItem("samplingCheckingVinNo");
    const dealerCode = sessionStorage.getItem("samplingCheckingDealerCode");
    // 表格展示
    const tableData = ref([]);
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 1,
      total: 0
    });

    const getTableData = () => {
      const params = {
        vinNo: vinNo,
        dealerCode,
        current: pagination.currentPage,
        size: pagination.pageSize
      };
      getOperationRecords({ params }).then((res: any) => {
        tableData.value = res.content;
        pagination.total = res.totalElements;
        const customerColumn = ["opRole", "opAction"];
        tableData.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, tableData.value, customerColumn)
          );
      });
    };

    getTableData();

    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page : 1;
      pagination.pageSize = pageSize;
      getTableData();
    };

    return {
      columns,
      status,
      tableData,
      pagination,
      getTableData,
      pageChange,
      sizeChange,
      tableHeight
    };
  }
});
